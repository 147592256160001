const siteHeader = document.querySelector('.site-header')
const menuToggle = document.querySelector('.menu-toogle')
const menuElement = document.querySelector('.header__main-menu')
const menuItems = document.querySelectorAll('.header__main-menu > .menu-item')
const headerButtons = document.querySelector('.header-container--right')

function switchSubMenu() {
    const clickedLink = event.target.closest('a')
    if (clickedLink) {
        if (clickedLink.getAttribute('href') === '#') {
            event.preventDefault()
        }
    }

    let isOpen = false
    if (this.classList.contains('is-opened')) {
        isOpen = true
    }

    menuItems.forEach(function(menuItem) {
        menuItem.classList.remove('is-opened')
        if (!menuToggle.classList.contains('is-opened')) {
            siteHeader.classList.remove('is-opened')
        }
    })

    if (!isOpen) {
        this.classList.add('is-opened')
        siteHeader.classList.add('is-opened')
    }
}

menuItems.forEach(function(menuItem) {
    menuItem.addEventListener('click', switchSubMenu)
})

menuToggle.addEventListener('click', function() {
    if (menuToggle.classList.contains('is-opened')) {
        menuToggle.classList.remove('is-opened')
        menuElement.classList.remove('is-opened')
        siteHeader.classList.remove('is-opened')
        headerButtons.classList.remove('is-opened')
        menuItems.forEach(function(menuItem) {
            menuItem.classList.remove('is-opened')
        })
    } else {
        menuToggle.classList.add('is-opened')
        menuElement.classList.add('is-opened')
        siteHeader.classList.add('is-opened')
        headerButtons.classList.add('is-opened')
    }
})

